import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { editorAdapter } from '../../external/common/controller/editor-adapter';
import { createFeedPageController } from '../../external/feed-page/controller';
import { createNoopController } from '../../external/common/controller/create-noop-controller';

const createController: CreateControllerFn = async ({
  appData,
  controllerConfig,
  flowAPI,
}) => {
  return editorAdapter({
    appData: appData || {},
    controllerConfig,
    flowAPI,
    createNoopController,
    createController: createFeedPageController,
  });
};

export default createController;
