import { createStore } from 'redux';
import {
  isExperimentEnabled,
  setResolveIdKey,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_STABLE_IDS_OOI } from '@wix/communities-blog-experiments';

export default function (reducers, initialState, enchanger) {
  if (isExperimentEnabled(initialState, EXPERIMENT_USE_STABLE_IDS_OOI)) {
    setResolveIdKey('id');
  }
  return createStore(reducers, initialState, enchanger);
}
