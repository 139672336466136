import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import {
  setLocale,
  setQueryLocaleAction,
} from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { initWixData } from '../../common/actions/init-wix-data';
import { getInstance, isFrameless } from '../../common/controller/helpers';
import { fetchInitialData } from '../actions/fetch-initial-data';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  appParams,
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(wixCodeApi, appParams));

  await Promise.all([
    store.dispatch(
      initWixData({
        viewMode,
        dataKeys: ['categoryId', 'tagId'],
        config,
        isFrameless: isFrameless({ state: store.getState(), wixCodeApi }),
      }),
    ),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(
      setBasicParams({
        viewMode,
        language,
        biPageNumber: platformAPIs.bi.pageNumber,
      }),
    ),
    store.dispatch(setAppConfig({ bundleName })),
    store.dispatch(setCustomRoutes()),
  ]);

  await store.dispatch(fetchInitialData());
}
