import { createAction } from '@wix/communities-blog-client-common';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const SUBMIT_POST_FOR_MODERATION_REQUEST =
  'post/SUBMIT_POST_FOR_MODERATION_REQUEST';
export const SUBMIT_POST_FOR_MODERATION_SUCCESS =
  'post/SUBMIT_POST_FOR_MODERATION_SUCCESS';
export const SUBMIT_POST_FOR_MODERATION_FAILURE =
  'post/SUBMIT_POST_FOR_MODERATION_FAILURE';

export const submitPostForModerationRequest = createAction(
  SUBMIT_POST_FOR_MODERATION_REQUEST,
);
export const submitPostForModerationSuccess = createAction(
  SUBMIT_POST_FOR_MODERATION_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const submitPostForModerationFailure = createAction(
  SUBMIT_POST_FOR_MODERATION_FAILURE,
);

export const submitPostForModeration =
  (postId) =>
  (dispatch, getState, { request }) => {
    const promise = request.post(`/v3/moderation/${postId}`);

    return promise
      .then(({ draftPost }) =>
        dispatch(submitPostForModerationSuccess({ draftPost })),
      )
      .catch((error) => dispatch(submitPostForModerationFailure(error)))
      .then(() => promise);
  };

export const submitPostForModerationPromisified = createPromisifiedAction(
  submitPostForModeration,
  (data) => data,
  (response) => response.status,
);
