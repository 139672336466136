import { SHOW_MESSAGE } from './show-message';
import { HIDE_MESSAGE } from './hide-message';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return action.payload;
    case HIDE_MESSAGE:
      return {};
    default:
      return state;
  }
};
