export const SUBMIT_FAST_FORM = 'SUBMIT_FAST_FORM';
export const FAST_FORM_SUBMIT_CALLBACK = 'FAST_FORM_SUBMIT_CALLBACK';

export const submitFastForm = (formName) => ({
  type: SUBMIT_FAST_FORM,
  payload: {
    formName,
    timestamp: Date.now(),
  },
});
export const fastFormSubmitCallback = (formName, formState) => ({
  type: FAST_FORM_SUBMIT_CALLBACK,
  payload: {
    formName,
    formState,
  },
});
