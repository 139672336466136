import {
  createPageUrl,
  SECTION_CATEGORY,
  getLastPage,
} from '@wix/communities-blog-client-common';
import {
  getEntityCount,
  getPaginationPageSize,
} from '../../selectors/pagination-selectors';
import { getSectionUrl } from '../../store/topology/topology-selectors';
import { formatTitle } from '../pagination';

export const getPaginationUrls = (
  url,
  page,
  lastPage,
  state,
  multilingualQueryParam = '',
) => {
  const urls = {};
  if (page > 1) {
    urls.prevUrl = `${createPageUrl(
      page - 1,
      url || getSectionUrl(state),
    )}${multilingualQueryParam}`;
  }
  if (page < lastPage) {
    urls.nextUrl = `${createPageUrl(
      page + 1,
      url || getSectionUrl(state),
    )}${multilingualQueryParam}`;
  }
  return urls;
};

const getPaginationTitle = (title, page, lastPage, t, updateTitle) => {
  if (updateTitle) {
    return formatTitle({ title, page, lastPage, t });
  }
  return title;
};

export const getPaginationItemData = ({
  itemData,
  state,
  url,
  page,
  t,
  updateTitle,
  multilingualQueryParam,
}) => {
  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const title = getPaginationTitle(
    itemData.title,
    page,
    lastPage,
    t,
    updateTitle,
  );
  const urls = getPaginationUrls(
    url,
    page,
    lastPage,
    state,
    multilingualQueryParam,
  );

  return { ...itemData, ...urls, title };
};
