import { isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { getCollapsibleListData } from '../../common/services/get-collapsible-list-faq-data';
import { WARMUP_DATA_SEO_FAQ_DATA } from '../constants/post-page';

export const getSEOFAQData = async ({ state, wixCodeApi, post }) => {
  const { getCollapsibleListFAQData } = await getCollapsibleListData.load();

  let faq;
  if (isSSR(state)) {
    faq = getCollapsibleListFAQData(post.content);
    wixCodeApi.window.warmupData.set(WARMUP_DATA_SEO_FAQ_DATA, faq);
  } else {
    faq =
      wixCodeApi.window.warmupData.get(WARMUP_DATA_SEO_FAQ_DATA) ??
      getCollapsibleListFAQData(post.content);
  }

  return faq;
};
