import { get } from 'lodash';
import { platformizedPostMapper } from '@wix/communities-blog-wix-code-common';

export const mapToPlatformizedPost = (restPost) => {
  const rpcPost = {
    ...restPost,
    firstPublishedDate: restPost.firstPublishedDate
      ? new Date(restPost.firstPublishedDate)
      : null,
    lastPublishedDate: restPost.lastPublishedDate
      ? new Date(restPost.lastPublishedDate)
      : null,
  };
  const rpcResult = platformizedPostMapper.map(rpcPost);
  return {
    ...rpcResult,
    publishedDate: get(rpcResult, 'publishedDate.$date')
      ? new Date(rpcResult.publishedDate.$date)
      : null,
    lastPublishedDate: get(rpcResult, 'lastPublishedDate.$date')
      ? new Date(rpcResult.lastPublishedDate.$date)
      : null,
  };
};
