import { isArray } from 'lodash';
import createAction from '../../services/create-action';
import { getValue, getValues } from '../../services/wix-data';
export var FETCH_WIX_DATA_REQUEST = 'wixData/FETCH_REQUEST';
export var FETCH_WIX_DATA_SUCCESS = 'wixData/FETCH_SUCCESS';
export var FETCH_WIX_DATA_FAILURE = 'wixData/FETCH_FAILURE';
export var fetchWixDataRequest = createAction(FETCH_WIX_DATA_REQUEST);
export var fetchWixDataSuccess = createAction(FETCH_WIX_DATA_SUCCESS);
export var fetchWixDataFailure = createAction(FETCH_WIX_DATA_FAILURE);
export var fetchWixData = function (Wix, key) { return function (dispatch) {
    dispatch(fetchWixDataRequest(key));
    var getData = isArray(key) ? getValues : getValue;
    return getData(Wix, key)
        .then(function (response) { return dispatch(fetchWixDataSuccess(response)); })
        .catch(function (error) { return dispatch(fetchWixDataFailure(error)); });
}; };
