import { getPostByIdOrSlug } from '../../selectors/post-selectors';
import { isPublished } from '../event-helpers';
import { INCREMENT_POST_LIKE_COUNT_SUCCESS } from '../../store/post-likes/post-likes-actions-types';

const handler = (action, state) => {
  const { id, isLiked, likeCount } = action.payload;
  const post = getPostByIdOrSlug(state, id);

  return {
    evid: 209,
    flag: Number(isLiked),
    likes_count: likeCount,
    is_published: isPublished(post),
    post_id: post._id,
    post_stable_id: post.id,
    is_demo: post.isDemo,
    eventMeta: {
      description: 'like click',
    },
  };
};

export const uouIncrementPostLikeCountEvent = {
  [INCREMENT_POST_LIKE_COUNT_SUCCESS]: handler,
};
