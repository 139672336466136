var SCOPE_OPTIONS = { scope: 'COMPONENT' };
export var getValue = function (Wix, key) {
    return new Promise(function (resolve, reject) {
        Wix.Data.Public.get(key, SCOPE_OPTIONS, resolve, reject);
    });
};
export var getValues = function (Wix, keys) {
    return Promise.all(keys.map(function (key) { return getValue(Wix, key).catch(function () { return null; }); })).then(function (res) {
        return res.reduce(function (values, value) {
            if (value) {
                values[Object.keys(value)[0]] = value[Object.keys(value)[0]];
            }
            return values;
        }, {});
    });
};
export var setValue = function (Wix, key, value) {
    return new Promise(function (resolve, reject) {
        Wix.Data.Public.set(key, value, SCOPE_OPTIONS, resolve, reject);
    });
};
