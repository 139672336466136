export var capitalizeLanguageCode = function (langCode) {
    if (!langCode) {
        return '';
    }
    var _a = langCode.split('-'), part1 = _a[0], part2 = _a[1];
    if (!part1 || !part2) {
        return langCode;
    }
    return "".concat(part1, "-").concat(part2.toUpperCase());
};
