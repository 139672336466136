import createAction from '../../services/create-action';
import { setValue } from '../../services/wix-data';
export var SET_WIX_DATA_REQUEST = 'wixData/SET_REQUEST';
export var SET_WIX_DATA_SUCCESS = 'wixData/SET_SUCCESS';
export var SET_WIX_DATA_FAILURE = 'wixData/SET_FAILURE';
export var setWixDataRequest = createAction(SET_WIX_DATA_REQUEST);
export var setWixDataSuccess = createAction(SET_WIX_DATA_SUCCESS, function (payload) { return payload; }, function (payload, meta) { return meta; });
export var setWixDataFailure = createAction(SET_WIX_DATA_FAILURE);
export var setWixData = function (Wix, key, value, type) { return function (dispatch) {
    var _a;
    dispatch(setWixDataRequest((_a = {}, _a[key] = value, _a)));
    return setValue(Wix, key, value)
        .then(function (response) { return dispatch(setWixDataSuccess(response, type)); })
        .catch(function (error) { return dispatch(setWixDataFailure(error)); });
}; };
