import { createAction } from '@wix/communities-blog-client-common';

export const SET_USER = 'user/SET';

export const setUser = (payload) => (dispatch) => {
  dispatch({ type: SET_USER, payload });
  return Promise.resolve();
};

export const setUserThunk = createAction(SET_USER);
