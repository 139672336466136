import { pick } from 'lodash';
import { resolveId } from '@wix/communities-blog-client-common';

import { FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_POST_SUCCESS } from '../../actions/fetch-post';
import { FETCH_POST_METADATA_SUCCESS } from '../../actions/fetch-post-metadata';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { UPDATE_POST_COUNTERS } from './post-counters-actions';
import { SET_POSTS } from '../../actions/set-posts';
import { FETCH_RELATED_POSTS_SUCCESS } from '../../../post-page/actions/fetch-related-posts';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../../actions/fetch-hashtag-posts';

export default function postCounters(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_POST_SUCCESS:
      return post(state, payload.post);
    case FETCH_POST_METADATA_SUCCESS:
      return post(state, payload);
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_RELATED_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_HASHTAG_POSTS_SUCCESS:
    case SET_POSTS:
      return posts(state, payload);
    case UPDATE_POST_COUNTERS:
      return updatePostCounters(state, payload);
    default:
      return state;
  }
}

function post(state, post) {
  return { ...state, [resolveId(post)]: getCounters(post) };
}

function posts(state, posts) {
  return {
    ...state,
    ...posts.reduce((result, post) => {
      result[resolveId(post)] = getCounters(post);
      return result;
    }, {}),
  };
}

function updatePostCounters(state, payload) {
  const id = resolveId(payload);
  const { viewCount, totalComments } = payload;
  const post = state[id];

  if (
    !post ||
    (post.viewCount === viewCount && post.totalComments === totalComments)
  ) {
    return state;
  }

  return { ...state, [id]: { ...post, viewCount, totalComments } };
}

function getCounters(post) {
  return pick(post, [
    'viewCount',
    'viewCountFormatted',
    'totalComments',
    'totalCommentsFormatted',
  ]);
}
