export async function initWixCommentsController({
  config,
  setProps,
  wixCodeApi,
  platformAPIs,
  type,
  compId,
  store,
  httpClient,
}) {
  const initializeCommentsController = (
    await import(
      /* webpackChunkName: "wix-comments-controller" */ './wix-comments-controller'
    )
  ).default;

  return initializeCommentsController({
    config,
    setProps,
    wixCodeApi,
    platformAPIs,
    type,
    compId,
    store,
    httpClient,
  });
}
