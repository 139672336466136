import { debounce, throttle } from 'lodash';

export const DEBOUNCE_CANCEL = 'redux-debounce/CANCEL';
export const DEBOUNCE_FLUSH = 'redux-debounce/FLUSH';

export const THROTTLE_CANCEL = 'redux-throttle/CANCEL';
export const THROTTLE_FLUSH = 'redux-throttle/FLUSH';

function map(queue, action, method) {
  if (action.payload && action.payload.type) {
    let types = action.payload.type;
    if (!Array.isArray(types)) {
      types = [types];
    }
    Object.keys(queue)
      .filter((t) => types.includes(t))
      .forEach((t) => queue[t][method]());
    return;
  }
  Object.keys(queue).forEach((t) => queue[t][method]());
  return;
}

export function debounceActions(defaultWait = 300, defaultThrottleOption = {}) {
  const debounced = {};
  return () => (next) => (action) => {
    if (action.type === DEBOUNCE_CANCEL) {
      map(debounced, action, 'cancel');
      return next(action);
    }

    if (action.type === DEBOUNCE_FLUSH) {
      map(debounced, action, 'flush');
      return next(action);
    }

    const shouldDebounce = (action.meta || {}).debounce;

    // check if we don't need to debounce the action
    if (!shouldDebounce) {
      return next(action);
    }

    if (debounced[action.type]) {
      // if it's a action which was throttled already
      return debounced[action.type](action);
    }

    let wait = defaultWait;
    let options = defaultThrottleOption;

    if (!isNaN(shouldDebounce) && shouldDebounce !== true) {
      wait = shouldDebounce;
    } else if (typeof shouldDebounce === 'object') {
      wait = shouldDebounce.wait || defaultWait;
      options = { ...defaultThrottleOption, ...shouldDebounce };
    }

    debounced[action.type] = debounce(next, wait, options);

    return debounced[action.type](action);
  };
}

export function throttleActions(defaultWait = 300, defaultThrottleOption = {}) {
  const throttled = {};
  return () => (next) => (action) => {
    if (action.type === THROTTLE_CANCEL) {
      map(throttled, action, 'cancel');
      return next(action);
    }

    if (action.type === THROTTLE_FLUSH) {
      map(throttled, action, 'flush');
      return next(action);
    }

    const shouldThrottle = (action.meta || {}).throttle;

    // check if we don't need to throttle the action
    if (!shouldThrottle) {
      return next(action);
    }

    if (throttled[action.type]) {
      // if it's a action which was throttled already
      return throttled[action.type](action);
    }

    let wait = defaultWait;
    let options = defaultThrottleOption;

    if (!isNaN(shouldThrottle) && shouldThrottle !== true) {
      wait = shouldThrottle;
    } else if (typeof shouldThrottle === 'object') {
      wait = shouldThrottle.wait || defaultWait;
      options = { ...defaultThrottleOption, ...shouldThrottle };
    }

    throttled[action.type] = throttle(next, wait, options);

    return throttled[action.type](action);
  };
}
