import { createAction } from '@wix/communities-blog-client-common';
import { WP_BLOGS } from '@wix/communities-universal/dist/src/constants/wix-blogs';

export const SET_ENABLE_LINKIFY = 'editorConfig/SET_ENABLE_LINKIFY';

export const setEnableLinkfify = createAction(
  SET_ENABLE_LINKIFY,
  (instanceId) => {
    return !WP_BLOGS.includes(instanceId);
  },
);
