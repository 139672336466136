import { __spreadArray } from "tslib";
import { FETCH_TAG_SUCCESS } from './fetch-tag';
import { FETCH_TAGS_SUCCESS } from './fetch-tags';
export var tagsReducer = function (state, _a) {
    if (state === void 0) { state = []; }
    var _b = _a === void 0 ? {} : _a, type = _b.type, payload = _b.payload;
    switch (type) {
        case FETCH_TAG_SUCCESS:
            return __spreadArray(__spreadArray([], state, true), [payload], false);
        case FETCH_TAGS_SUCCESS:
            return payload;
        default:
            return state;
    }
};
export default tagsReducer;
