import { __assign } from "tslib";
import { identity } from 'lodash';
import { handleAggregatorResponse } from '../../services/aggregator';
import createAction from '../../services/create-action';
export var FETCH_TAGS_REQUEST = 'tags/FETCH_REQUEST';
export var FETCH_TAGS_SUCCESS = 'tags/FETCH_SUCCESS';
export var FETCH_TAGS_FAILURE = 'tags/FETCH_FAILURE';
export var fetchTagsRequest = createAction(FETCH_TAGS_REQUEST);
export var fetchTagsSuccess = createAction(FETCH_TAGS_SUCCESS, function (payload) { return payload; }, function (payload, meta) { return meta; });
export var fetchTagsFailure = createAction(FETCH_TAGS_FAILURE);
var fetchTagsAction = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.onlyWithPublishedPosts, onlyWithPublishedPosts = _c === void 0 ? false : _c, labelStarts = _b.labelStarts, _d = _b.limit, limit = _d === void 0 ? 500 : _d, _e = _b.mostPopular, mostPopular = _e === void 0 ? false : _e, language = _b.language;
    return function (dispatch, _getState, _a) {
        var request = _a.request;
        var multipleFilters = [onlyWithPublishedPosts, labelStarts, language].filter(identity).length > 1;
        var filter = multipleFilters
            ? {
                $and: [
                    onlyWithPublishedPosts && { publicationCount: { $gt: 0 } },
                    labelStarts && { label: { $startsWith: labelStarts } },
                    language && { language: { $eq: language } },
                ].filter(identity),
            }
            : __assign(__assign(__assign({}, (onlyWithPublishedPosts && { publicationCount: { $gt: 0 } })), (labelStarts && { label: { $startsWith: labelStarts } })), (language && { language: { $eq: language } }));
        return request.post('/v2/tags/query', __assign({ filter: filter, paging: {
                offset: 0,
                limit: limit,
            } }, (mostPopular && {
            sort: [{ fieldName: 'publicationCount', order: 'DESC' }],
        })), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    };
};
var createFetchTags = function (action) { return function (args) {
    if (args === void 0) { args = {}; }
    return function (dispatch) {
        dispatch(fetchTagsRequest());
        return dispatch(action(args))
            .then(function (_a) {
            var tags = _a.tags;
            return dispatch(fetchTagsSuccess(tags, Boolean(args.mostPopular)));
        })
            .catch(function () { return dispatch(fetchTagsFailure()); });
    };
}; };
export var fetchTags = createFetchTags(fetchTagsAction);
export var handleTagsResponse = createFetchTags(handleAggregatorResponse);
export default fetchTags;
