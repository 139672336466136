import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createMyPostsPageController } from '../../external/my-posts-page/controller';

const createController: CreateControllerFn = async ({
  appData,
  controllerConfig,
  flowAPI,
}) => {
  const controller = await createMyPostsPageController(
    controllerConfig,
    undefined,
    appData,
    flowAPI,
  );
  return controller as any;
};

export default createController;
