import { flatMap, trimStart } from 'lodash';
import Wix from './wix-sdk-polyfill';

export const getCurrentPage = () =>
  Promise.all([
    new Promise((resolve) => Wix.getCurrentPageId(resolve)),
    new Promise((resolve) => Wix.getSiteMap(resolve)),
  ]).then((values) => {
    const [currentPageId, sitePages] = values;
    const pages = flatMap(sitePages, (page) =>
      page.subPages ? [page, ...page.subPages] : page,
    )
      .filter((page) => page.type === 'PageLink')
      .map((page) => ({ ...page, pageId: trimStart(page.pageId, '#') }));

    const currentPage =
      pages.find((page) => page.pageId === currentPageId) || {};
    return currentPage;
  });
