import {
  createAction,
  urijs,
  getCategoryIds,
} from '@wix/communities-blog-client-common';
import { normalizePost } from '../services/post-utils';
import { encodeURISlug } from '../services/slug';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

const makeRequest = (postSlug, { includeDraft, request, instance }) => {
  if (!postSlug) {
    throw new Error('Missing postSlug');
  }
  const fieldsets = `categories,owner,likes,content,subscriptions,tags,seo,translations,urls`;
  const requestUrl = urijs(
    `/_api/posts/${!includeDraft ? 'content/' : ''}${postSlug}`,
  ).query({
    fieldsets,
  });
  return request(requestUrl, { instance });
};

export function fetchPost(postSlug, { includeDraft, instance } = {}) {
  return (dispatch, getState, { request, httpClient, wixCodeApi }) => {
    const promise = preFetchPost(postSlug, { includeDraft, instance })(
      dispatch,
      getState,
      { request },
    );
    return completeFetchPost(postSlug, promise)(dispatch, getState, {
      httpClient,
      wixCodeApi,
    });
  };
}

export const preFetchPost =
  (postSlug, { includeDraft, instance } = {}) =>
  (dispatch, getState, { request }) => {
    postSlug = encodeURISlug(postSlug);
    dispatch(fetchPostRequest({ postSlug }));
    return makeRequest(postSlug, { includeDraft, request, instance });
  };

export const completeFetchPost =
  (postSlug, preFetchResult) =>
  async (dispatch, getState, { wixCodeApi, httpClient }) => {
    postSlug = encodeURISlug(postSlug);

    try {
      let post;
      let capturedError;

      try {
        post = await preFetchResult;
      } catch (e) {
        capturedError = e;
        if (
          capturedError?.status === 404 &&
          getEnvironment(wixCodeApi).isEditorSegment
        ) {
          const resp = await getDemoPosts({
            httpClient,
            getState,
            dispatch,
            wixCodeApi,
            query: { slugs: [postSlug] },
          });
          post = resp?.posts?.[0];
          if (post) {
            capturedError = null;
          }
        }
      }

      if (capturedError) {
        throw capturedError;
      }

      const normalizedPost = normalizePost({
        state: getState(),
        post,
        blogCategoryIds: getCategoryIds(getState()),
      });

      dispatch(fetchPostSuccess({ post: normalizedPost, postSlug }));

      return normalizedPost;
    } catch (error) {
      dispatch(fetchPostFailure({ postSlug, error }));
      throw error;
    }
  };
