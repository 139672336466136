import {
  MODAL_TYPE_POST_EDITOR,
  getCustomPostUrl,
} from '@wix/communities-blog-client-common';
import { closeModal } from '../modals/framework/store/modal-actions';
import { getPost } from '../selectors/post-selectors';
import { isEditor } from '../store/basic-params/basic-params-selectors';
import { updatePost } from './update-post';
import {
  navigateWithinBlog,
  navigateToCustomPostUrl,
} from './navigate-within-blog';

const handleDiscardSuccess = (post) => (dispatch, getState) => {
  const state = getState();
  if (isEditor(state)) {
    dispatch(navigateWithinBlog('/'));
  } else {
    dispatch(
      navigateToCustomPostUrl(
        getCustomPostUrl(getState(), post.slug),
        `/${post.slug}`,
      ),
    );
  }
  dispatch(closeModal({ type: MODAL_TYPE_POST_EDITOR }));
};

export const discardPostDraft = (postId) => (dispatch, getState) => {
  dispatch(updatePost(getPost(getState(), postId))).then((post) =>
    dispatch(handleDiscardSuccess(post)),
  );
};
