import { BUTTON_CLICKED } from '../../actions/button-clicked';
import {
  BUTTON_CREATE_POST,
  BUTTON_SHARE_POST_MODAL,
  BUTTON_SHARE_POST,
} from '../bi-buttons';
import { getCurrentUserRole } from '@wix/communities-blog-client-common';
import { getPostByIdOrSlug } from '../../selectors/post-selectors';

const handler = (action, state) => {
  const post = getPostByIdOrSlug(state, action.payload.postId);

  switch (action.payload.action) {
    case BUTTON_CREATE_POST:
      return {
        evid: 208,
        eventMeta: {
          description: 'write new post click',
        },
      };
    case BUTTON_SHARE_POST_MODAL:
      return {
        evid: 213,
        eventMeta: {
          description: 'open share modal',
        },
        post_id: post._id,
        role: getCurrentUserRole(state),
        is_demo: post.isDemo,
      };
    case BUTTON_SHARE_POST:
      return {
        evid: 214,
        eventMeta: {
          description: 'share post',
        },
        shareType: action.payload.shareType,
        post_id: post._id,
        post_stable_id: post.id,
        is_demo: post.isDemo,
        role: getCurrentUserRole(state),
      };
    default:
      return;
  }
};

export const uouButtonClickedEvent = { [BUTTON_CLICKED]: handler };
