import { get } from 'lodash';
import {
  POST_PUBLISH,
  POST_SUBMITTED_FOR_MODERATION,
  POST_UPDATE,
  POST_UPDATED_IN_MODERATION,
} from '../../../common/components/message/message-types';
import { PUBLISH_POST_SUCCESS } from '../../../common/actions/publish-post-types';
import { SUBMIT_POST_FOR_MODERATION_SUCCESS } from '../../../common/actions/submit-post-for-moderation';

export default {
  [PUBLISH_POST_SUCCESS]: (action) =>
    get(action, 'meta.isUpdated') ? POST_UPDATE : POST_PUBLISH,
  [SUBMIT_POST_FOR_MODERATION_SUCCESS]: (action) =>
    get(action, 'meta.isUpdated')
      ? POST_UPDATED_IN_MODERATION
      : POST_SUBMITTED_FOR_MODERATION,
};
