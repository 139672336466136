import { createAction } from '@wix/communities-blog-client-common';
import {
  getMetaSiteId,
  getIsDemoMode,
  getSiteIsTemplate,
} from '../instance-values/instance-values-selectors';
import {
  FETCH_SITE_PROPERTIES_REQUEST,
  FETCH_SITE_PROPERTIES_SUCCESS,
  FETCH_SITE_PROPERTIES_FAILURE,
} from './site-properties-types';

export const fetchSitePropertiesRequest = createAction(
  FETCH_SITE_PROPERTIES_REQUEST,
);
export const fetchSitePropertiesSuccess = createAction(
  FETCH_SITE_PROPERTIES_SUCCESS,
);
export const fetchSitePropertiesFailure = createAction(
  FETCH_SITE_PROPERTIES_FAILURE,
);

export const fetchSiteProperties = () => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchSitePropertiesRequest());

    const state = getState();
    const shouldSkipFetch =
      !getMetaSiteId(state) || getIsDemoMode(state) || getSiteIsTemplate(state);
    if (shouldSkipFetch) {
      dispatch(fetchSitePropertiesSuccess({}));
      return;
    }

    const promise = request(`/_api/site-properties`);
    return promise
      .then(
        (siteProperties) =>
          dispatch(fetchSitePropertiesSuccess(siteProperties)),
        () => dispatch(fetchSitePropertiesFailure()),
      )
      .then(() => Promise.resolve());
  };
};
