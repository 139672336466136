import { createAction } from '@wix/communities-blog-client-common';

export const ADD_TEXT_COLOR_REQUEST = 'rceSettings/ADD_TEXT_COLOR_REQUEST';
export const ADD_TEXT_COLOR_SUCCESS = 'rceSettings/ADD_TEXT_COLOR_SUCCESS';
export const ADD_TEXT_COLOR_FAILURE = 'rceSettings/ADD_TEXT_COLOR_FAILURE';
export const addTextColorRequest = createAction(ADD_TEXT_COLOR_REQUEST);
export const addTextColorSuccess = createAction(ADD_TEXT_COLOR_SUCCESS);
export const addTextColorFailure = createAction(ADD_TEXT_COLOR_FAILURE);

export const addTextColor =
  (color) =>
  (dispatch, getState, { request }) => {
    dispatch(addTextColorRequest({ color }));

    return request
      .post('/_api/rce-settings', { color })
      .then((rceSettings) => dispatch(addTextColorSuccess({ rceSettings })))
      .catch(() => dispatch(addTextColorFailure()));
  };

export const FETCH_RCE_SETTINGS_REQUEST = 'rceSettings/FETCH_REQUEST';
export const FETCH_RCE_SETTINGS_SUCCESS = 'rceSettings/FETCH_SUCCESS';
export const FETCH_RCE_SETTINGS_FAILURE = 'rceSettings/FETCH_FAILURE';
export const fetchRceSettingsRequest = createAction(FETCH_RCE_SETTINGS_REQUEST);
export const fetchRceSettingsSuccess = createAction(FETCH_RCE_SETTINGS_SUCCESS);
export const fetchRceSettingsFailure = createAction(FETCH_RCE_SETTINGS_FAILURE);

export const fetchRceSettings =
  () =>
  (dispatch, getState, { request }) => {
    dispatch(fetchRceSettingsRequest());

    return request('/_api/rce-settings')
      .then((rceSettings) => dispatch(fetchRceSettingsSuccess({ rceSettings })))
      .catch(() => dispatch(fetchRceSettingsFailure()));
  };
