import {
  createAction,
  handleAggregatorResponse,
} from '@wix/communities-blog-client-common';

export const CATEGORY_MENU_WIDGET_SUCCESS = 'categoryMenuWidget/SUCCESS';
export const CATEGORY_MENU_WIDGET_FAILURE = 'categoryMenuWidget/FAILURE';

export const categoryMenuWidgetSuccess = createAction(
  CATEGORY_MENU_WIDGET_SUCCESS,
);
export const categoryMenuWidgetFailure = createAction(
  CATEGORY_MENU_WIDGET_FAILURE,
);

const createCategoryMenuWidget =
  (action) =>
  (...args) =>
  (dispatch) => {
    return dispatch(action(...args))
      .then((categoryMenu) => dispatch(categoryMenuWidgetSuccess(categoryMenu)))
      .catch(() => dispatch(categoryMenuWidgetFailure()));
  };

export const handleCategoryMenuWidgetResponse = createCategoryMenuWidget(
  handleAggregatorResponse,
);
