import { APP_LOADED } from './app-loaded';

export const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case APP_LOADED:
      return true;
    default:
      return state;
  }
};
