import {
  createRequestWithBaseUrl,
  appendOriginInBackend,
} from '../controller/helpers';
import getEnvironment from './get-environment';

export default function createRequests({
  appParams: { baseUrls },
  wixCodeApi,
  bundleName,
  getStore,
  flowAPI,
}) {
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const {
    apiBaseUrlClient,
    apiPlatformizedBaseUrlClient,
    apiPaywallBaseUrl,
    apiAggregatorBaseUrl,
  } = baseUrls;
  const createRequest = createRequestWithBaseUrl({
    wixCodeApi,
    getStore,
    bundleName,
    flowAPI,
  });

  const blogApiBaseUrl =
    isEditor || isPreview
      ? apiBaseUrlClient
      : `${wixCodeApi.location.baseUrl}${apiBaseUrlClient}`;
  const request = createRequest(blogApiBaseUrl);

  const platformizedRequestUrl = appendOriginInBackend({
    wixCodeApi,
    baseUrl: apiPlatformizedBaseUrlClient,
  });
  const platformizedRequest = createRequest(platformizedRequestUrl);

  const paywallRequestUrl = appendOriginInBackend({
    wixCodeApi,
    baseUrl: apiPaywallBaseUrl,
  });
  const paywallRequest = createRequest(paywallRequestUrl);

  const aggregatorRequestUrl = appendOriginInBackend({
    wixCodeApi,
    baseUrl: apiAggregatorBaseUrl,
  });
  const aggregatorRequest = createRequest(aggregatorRequestUrl);

  return {
    blogApiBaseUrl,
    request,
    platformizedRequest,
    paywallRequest,
    aggregatorRequest,
  };
}
