import { FETCH_BLOG_DATA_SUCCESS } from '../actions/fetch-app-data';
import { SET_BLOG_DATA } from '../actions/set-app-data';
import { UPDATE_BLOG_DATA_SUCCESS } from '../actions/update-app-data';
export default function appData(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case FETCH_BLOG_DATA_SUCCESS:
        case UPDATE_BLOG_DATA_SUCCESS:
        case SET_BLOG_DATA:
            return action.payload;
        default:
            return state;
    }
}
