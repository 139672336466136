import { merge } from 'lodash';
import {
  SET_APP_SETTINGS,
  UPDATE_APP_SETTINGS_ON_STORE,
} from './app-settings-actions';

const getUpdatedAt = () => new Date().getTime();

export const appSettings = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_APP_SETTINGS:
    case UPDATE_APP_SETTINGS_ON_STORE:
      return merge({}, state, action.payload, { updatedAt: getUpdatedAt() });
    default:
      return state;
  }
};
