import { setInstance } from '../store/basic-params/basic-params-actions';
import { initialiseInstanceValues } from '../store/instance-values/instance-values-actions';

export default function listenToInstanceChange(wixCodeApi, appParams, store) {
  if (wixCodeApi.site.onInstanceChanged) {
    wixCodeApi.site.onInstanceChanged(({ instance }) => {
      store.dispatch(initialiseInstanceValues(instance));
      store.dispatch(setInstance(instance));
    }, appParams.appDefinitionId);
  }
}
