import { getViewMode } from '../selectors/search-params-selectors';
import createAction from '../services/create-action';
import { MIGRATION_STATUS_REDIRECTS_SET_FAILED } from '../constants/migration-status';
export var CONTENT_MIGRATION_SET_REDIRECTS_REQUEST = 'contentMigration/SET_REDIRECTS_REQUEST';
export var CONTENT_MIGRATION_SET_REDIRECTS_SUCCESS = 'contentMigration/SET_REDIRECTS_SUCCESS';
export var CONTENT_MIGRATION_SET_REDIRECTS_FAILURE = 'contentMigration/SET_REDIRECTS_FAILURE';
export var contentMigrationSetRedirectsRequest = createAction(CONTENT_MIGRATION_SET_REDIRECTS_REQUEST);
export var contentMigrationSetRedirectsSuccess = createAction(CONTENT_MIGRATION_SET_REDIRECTS_SUCCESS);
export var contentMigrationSetRedirectsFailure = createAction(CONTENT_MIGRATION_SET_REDIRECTS_FAILURE);
var MIGRATION_SET_REDIRECTS_PATH = '/_api/content-migration/set-redirects';
export var getMigrationSetRedirectsPath = function (viewMode) {
    return "".concat(MIGRATION_SET_REDIRECTS_PATH, "?viewMode=").concat(viewMode);
};
export var contentMigrationSetRedirects = function () { return function (dispatch, getState, _a) {
    var request = _a.request;
    dispatch(contentMigrationSetRedirectsRequest());
    var viewMode = getViewMode(getState());
    var promise = request.post(getMigrationSetRedirectsPath(viewMode));
    return promise
        .then(function (details) { return dispatch(contentMigrationSetRedirectsSuccess(details)); }, function () {
        return dispatch(contentMigrationSetRedirectsFailure(MIGRATION_STATUS_REDIRECTS_SET_FAILED));
    })
        .then(function () { return promise; });
}; };
