import { SET_APP_CONFIG } from './app-config-actions';
export function appConfigReducer(state, action) {
    if (state === void 0) { state = {}; }
    switch (action.type) {
        case SET_APP_CONFIG:
            return action.payload;
        default:
            return state;
    }
}
