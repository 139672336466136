import { getPostByIdOrSlug } from '../../selectors/post-selectors';

export const UPDATE_POST_COUNTERS = 'post/UPDATE_POST_COUNTERS';
export const updatePostCounters = (_payload) => (dispatch, getState) => {
  // TODO: STABLE_IDS migration
  const post = getPostByIdOrSlug(getState(), _payload?._id);
  const payload = post ? { ..._payload, id: post.id } : _payload;
  return dispatch({
    type: UPDATE_POST_COUNTERS,
    payload,
  });
};
