import { createPostPageApi } from './create-post-page-api';

export const createOldPostPageController = ({
  appParams,
  wixCodeApi,
  flowAPI,
}) => {
  return Promise.resolve({
    pageReady: () => {},
    exports: () => createPostPageApi(appParams.instance, wixCodeApi, flowAPI),
  }).catch(console.error);
};
