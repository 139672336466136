import { SET_SEO_PATTERNS } from '../../common/actions/seo-patterns';

export default function seoPatterns(state = {}, action) {
  switch (action.type) {
    case SET_SEO_PATTERNS:
      return action.payload;

    default:
      return state;
  }
}
