import { __assign } from "tslib";
import { FETCH_WIX_DATA_SUCCESS } from './fetch-wix-data';
import { SET_WIX_DATA_SUCCESS } from './set-wix-data';
export var INITIAL_STATE = {};
var wixData = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case FETCH_WIX_DATA_SUCCESS:
        case SET_WIX_DATA_SUCCESS:
            return __assign(__assign({}, state), action.payload);
        default:
            return state;
    }
};
export default wixData;
