import { joinChannel, leaveChannel } from '../../common/actions/ws-actions';
import { getChannel } from '../../common/services/ws-channel';

export const emitOpenPost = (post) =>
  joinChannel(getChannel({ type: 'post', ...post }), true);
export const emitClosePost = (post) =>
  leaveChannel(getChannel({ type: 'post', ...post }));

export const UPDATE_POST = 'post/UPDATE';
export const updatePost = (payload) => ({
  type: UPDATE_POST,
  payload,
});
