import { combineReducers, applyMiddleware } from 'redux';
import createStore from '../../common/services/create-store';
import thunk from 'redux-thunk';
import createRequests from '../../common/services/create-requests';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import commonReducers from '../../common/reducers';
import postCount from '../../common/store/post-count/post-count-reducer';
import createShowMessage from '../../common/messages/framework/create-show-message';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import { modalResolverMapByType } from '../containers/modals/modal-resolver-map-by-type';
import { modalDataLoaderByType } from '../containers/modals/modal-data-loader-map-by-type';
import messageResolvers from '../containers/messages/message-resolvers';
import viewedUser from '../store/viewed-user/viewed-user-reducer';
import activeTab from '../store/active-tab/active-tab-reducer';

export function createReduxStore({
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  isSSR,
  language,
  bundleName,
  flowAPI,
}) {
  const p = {};
  const getStore = () => p.store;

  const { request, aggregatorRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      aggregatorRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
    }),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
    createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
    createModalOpenedMiddleware({ modalDataLoaderByType }),
    createMessageMiddleware({ resolvers: messageResolvers }),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    postCount,
    viewedUser,
    activeTab,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
