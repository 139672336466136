import {
  createAction,
  handleAggregatorResponse,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_ADVANCED_SEO_IN_CATEGORY_FEED } from '@wix/communities-blog-experiments';
import { getQueryLocale } from '../../selectors/locale-selectors';

export const FETCH_CATEGORIES_REQUEST = 'categories/FETCH_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'categories/FETCH_FAILURE';

export const fetchCategoriesRequest = createAction(FETCH_CATEGORIES_REQUEST);
export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);
export const fetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);

const fetchCategoriesAction =
  () =>
  (_dispatch, getState, { request }) => {
    const state = getState();
    const isUseAdvancedSEOInCategoryEnabled = isExperimentEnabled(
      state,
      EXPERIMENT_USE_ADVANCED_SEO_IN_CATEGORY_FEED,
    );
    const lang = getQueryLocale(state);
    const langQuery = lang ? `&lang=${lang}` : '';
    const includeSeoQuery = isUseAdvancedSEOInCategoryEnabled
      ? '&includeSeo=true'
      : '';

    return request(
      `/_api/categories?offset=0&size=500&includeTranslations=true${includeSeoQuery}${langQuery}`,
    );
  };

const createFetchCategories =
  (action) =>
  (...args) =>
  (dispatch) => {
    dispatch(fetchCategoriesRequest());

    return dispatch(action(...args))
      .then((categories) => dispatch(fetchCategoriesSuccess(categories)))
      .catch(() => dispatch(fetchCategoriesFailure()));
  };

const fetchCategories = createFetchCategories(fetchCategoriesAction);
export const handleCategoriesResponse = createFetchCategories(
  handleAggregatorResponse,
);

export default fetchCategories;
