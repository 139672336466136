import { compact } from 'lodash';
import { applyMiddleware } from 'redux';
import createStore from '../../common/services/create-store';
import thunk from 'redux-thunk';
import {
  debounceActions,
  throttleActions,
} from '../../common/middleware/redux-middlewares';
import {
  FETCH_BLOG_DATA_SUCCESS,
  SET_APP_CONFIG,
} from '@wix/communities-blog-client-common';

import { createFastFormSubmitMiddleware } from '../../common/components/fast-form/store/fast-form-submit-middleware';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import createShowMessage from '../../common/messages/framework/create-show-message';
import {
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../../common/middleware/duplexer-middleware';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import messageResolvers from '../components/messages/message-resolvers';
import {
  appUouBiMiddleware,
  appUsersBiMiddleware,
} from '../../common/bi-events/init-middleware';
import { eventMap } from '../bi-events/uou-events';
import { usersEventMap } from '../bi-events/users-events';
import { getInstance } from '../../common/controller/helpers';
import createRequests from '../../common/services/create-requests';
import createRouterMiddleware from '../../common/middleware/create-router-middleware';
import { waitForActionMiddleware } from '../../common/store/wait-for-action/wait-for-action-middleware';
import { SET_APP_SETTINGS } from '../../common/store/app-settings/app-settings-actions';
import { FETCH_SITE_PROPERTIES_SUCCESS } from '../../common/store/site-properties/site-properties-types';
import { FETCH_TOPOLOGY_SUCCESS } from '../../common/store/topology/topology-actions';
import { SET_INSTANCE_VALUES } from '../../common/store/instance-values/instance-values-actions';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/store/categories/fetch-categories';
import { SET_BASIC_PARAMS } from '../../common/store/basic-params/basic-params-actions';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';

export function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  platformAPIs,
  getRouter,
  isSSR,
  sentry,
  isEditor,
  isPreview,
  language,
  bundleName,
  flowAPI,
}) {
  const p = {};
  const { disableDuplexerForAll, disableDuplexerForInstanceIds } =
    appParams.baseUrls;

  const { request, platformizedRequest, paywallRequest } = createRequests({
    appParams,
    wixCodeApi,
    bundleName,
    getStore: () => p.store,
    flowAPI,
  });

  const disableDuplexerByInstanceId = (disableDuplexerForInstanceIds || '')
    .split(',')
    .includes(appParams.instanceId);
  const useDuplexer =
    !disableDuplexerForAll &&
    !isSSR &&
    !isEditor &&
    !isPreview &&
    !disableDuplexerByInstanceId;

  const middleware = compact([
    throttleActions(),
    debounceActions(),
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      wixCodeApi,
      paywallRequest,
      compId,
      appParams,
      fedopsLogger,
      getRouter,
      httpClient: flowAPI.httpClient,
    }),
    waitForActionMiddleware([
      SET_APP_SETTINGS,
      SET_APP_CONFIG,
      SET_INSTANCE_VALUES,
      SET_BASIC_PARAMS,
      FETCH_BLOG_DATA_SUCCESS,
      FETCH_SITE_PROPERTIES_SUCCESS,
      FETCH_TOPOLOGY_SUCCESS,
      FETCH_CATEGORIES_SUCCESS,
    ]),
    createFastFormSubmitMiddleware({}),
    (isEditor || isPreview) && createRouterMiddleware(getRouter),
    createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
    createModalOpenedMiddleware({ modalDataLoaderByType }),
    createMessageMiddleware({ resolvers: messageResolvers }),
    postMetadataMiddleware({ isSSR, language }),
    // createInteractionsMiddleware(fedopsLogger, {
    //   [CREATE_POST_SUCCESS]: CREATE_POST,
    //   [UPDATE_POST_SUCCESS]: UPDATE_POST,
    //   [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
    //   [DELETE_POST_SUCCESS]: DELETE_POST,
    //   [CREATE_COMMENT_SUCCESS]: CREATE_COMMENT,
    //   [UPDATE_COMMENT_SUCCESS]: UPDATE_COMMENT,
    //   [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: LIKE_COMMENT,
    //   [DELETE_COMMENT_SUCCESS]: DELETE_COMMENT,
    // }),
    isEditor
      ? appUsersBiMiddleware({
          platformAPIs,
          instanceId: appParams.instanceId,
          eventMap: usersEventMap,
        })
      : appUouBiMiddleware({
          platformAPIs,
          instanceId: appParams.instanceId,
          eventMap,
        }),
    useDuplexer &&
      createDuplexerMiddleware({
        getInstance: getInstance(wixCodeApi),
        eventHandlers: socketEventHandlers,
        instanceId: appParams.instanceId,
        captureMessage: (message, extra) =>
          sentry.captureException(message, { extra }),
      }),
  ]);

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
