import {
  ENTITY_TYPE_POSTS,
  fetchAppDataSuccess,
  fetchTagsSuccess,
  fetchTPASettingsSuccess,
  getCategoryIds,
  getLocales,
  getDefaultSiteLocale,
} from '@wix/communities-blog-client-common';

import { setUser } from '../../common/store/auth/set-user';
import { fetchCategoriesSuccess } from '../../common/store/categories/fetch-categories';
import { fetchPaywallBanner } from '../../common/store/paywall/paywall-actions';
import { fetchTranslationsSuccess } from '../../common/store/translations/translations-actions';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import { normalizePosts } from '../../common/services/post-utils';
import { fetchFeedPostsSuccess } from '../../common/actions/fetch-feed-posts';
import { renderModel } from '../api/feed.api';

export const fetchFeedRenderModel =
  ({ language, translationsName, loginInfo, page = 1, pageSize = 3 }) =>
  async (dispatch, getState, { httpClient }) => {
    const state = getState();

    const metaSiteId = getMetaSiteId(state);
    const locale = getQueryLocale(state);
    const languageCode =
      locale ||
      language ||
      (getLocales(state) && getDefaultSiteLocale(state)?.id) ||
      null;

    const { data } = await httpClient.request(
      renderModel({
        locale,
        languageCode,
        translationsName,
        loginInfo,
        metaSiteId,
        page,
        pageSize,
      }),
    );

    const {
      currentUser,
      posts,
      categories,
      tags,
      settings,
      translations,
      appData,
    } = data;

    // TODO: refactor consumer to fallback on label when menuLabel is not available
    categories.forEach((x) => {
      x.menuLabel = x.label;
    });

    console.log('## fetchFeedRenderModel', {
      metaSiteId,
      currentUser,
      posts,
      categories,
      tags,
      settings,
      translations,
      appData,
    });

    return Promise.all([
      currentUser ? dispatch(setUser(currentUser)) : Promise.resolve(),
      dispatch(fetchTPASettingsSuccess(settings)),
      dispatch(fetchCategoriesSuccess(categories)),
      dispatch(fetchPaywallBanner()),
      dispatch(fetchAppDataSuccess(appData)),
      dispatch(fetchTranslationsSuccess(translations)),
      dispatch(fetchTagsSuccess(tags)),
      dispatch(
        fetchFeedPostsSuccess(
          normalizePosts({
            state: { categories, translations },
            posts: posts.posts,
            blogCategoryIds: getCategoryIds({ categories }),
            origin: '/v3/posts',
          }),
          {
            page,
            entityType: ENTITY_TYPE_POSTS,
            entityCount: posts.metaData?.total,
            pageSize,
          },
        ),
      ),
    ]);
  };
